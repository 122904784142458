import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const DevicesImageSection = ({
	image1,
	imageAlt1,
	image2,
	imageAlt2,
	image3,
	imageAlt3,
}) => {
	return (
		<section className="py-5 py-lg-7 ">
			<Container>
				<Row className="g-5 align-items-center">
					<Col md={5}>
						<GatsbyImage image={image1} alt={imageAlt1} className="w-100" />
					</Col>
					<Col md={5}>
						<GatsbyImage image={image2} alt={imageAlt2} className="w-100" />
					</Col>
					<Col md={2}>
						<GatsbyImage image={image3} alt={imageAlt3} className="w-100" />
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default DevicesImageSection;
