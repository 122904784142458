import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { Link } from "gatsby";
import { Button } from "react-bootstrap";
import Navigation from "./navigation";

const HeroCS = ({
	title,
	description,
	image,
	imageAlt,
	button1,
	button2,
	button1Text,
	button2Text,
	button1Link,
	button2Link,
	button1Var,
	button2Var,
	backgroundColour,
}) => {
	return (
		<div className="bg-primary">
			<Navigation bg="primary" />
			<div
				style={{ background: backgroundColour }}
				className="position-relative "
			>
				<StaticImage
					src="../images/hero-rjm copy.svg"
					placeholder="blurred"
					quality={100}
					style={{
						zIndex: 0,
						maxWidth: "400px",
						height: "660px",
						opacity: 0.2,
					}}
					className="mb-4 position-absolute start-0 top-50 translate-middle-y"
				/>
				<Container style={{ zIndex: 1 }} className=" position-relative py-5 ">
					<Row className=" pb-5 align-items-center justify-content-between">
						<Col lg={6} className="text-white">
							<h1 className=" mb-3 ssp-bold text-uppercase display-1 hero-title">
								{title}
							</h1>
							<p
								className="mb-lg-0 "
								style={{ fontSize: "140%", color: " #d1d1d1" }}
							>
								{description}
							</p>
							{button1 !== null && (
								<Button
									className={`mt-5 w-100 w-md-auto fs-5 me-md-4 py-2 px-4 ${button1}`}
									variant={button1Var}
									as={Link}
									to={button1Link}
								>
									{button1Text}
								</Button>
							)}
							{button2 !== null && (
								<Button
									className={`mt-4 mt-md-5 fs-5 w-100 d-none d-md-inline-block w-md-auto py-2 px-4 ${button2}`}
									variant={button2Var}
									as={Link}
									to={button2Link}
								>
									{button2Text}
								</Button>
							)}
						</Col>
						<Col lg={5}>
							<GatsbyImage className="w-100" image={image} alt={imageAlt} />
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default HeroCS;
